import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { setPassword } from '../services/authService';
import api from '../services/api';
import styles from '../styles/SetPassword.module.css';

const SetPassword = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [password, setPasswordValue] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        console.log('Fetching email for uid:', uid);
        const response = await api.get(`/api/accounts/get-email/${uid}/`);
        console.log('Email response:', response.data);
        setEmail(response.data.email);
      } catch (error) {
        console.error('Error fetching email:', error);
        setError('Failed to fetch email');
      }
    };

    if (uid) {
      fetchEmail();
    }
  }, [uid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      console.log('Setting password for:', { uid, token, email });
      await setPassword(uid, token, password);
      navigate('/login', { 
        state: { 
          message: 'Password set successfully. Please login with your new password.',
          email: email
        }
      });
    } catch (err) {
      console.error('Error setting password:', err);
      setError(err.response?.data?.message || 'Failed to set password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.formWrapper}>
          <div className={styles.header}>
            <h1>Set Your Password</h1>
            {email && (
              <p>Setting password for: <strong>{email}</strong></p>
            )}
            <p>Please create a password for your MisterTipster VIP account</p>
          </div>
          
          {error && <div className={styles.errorMessage}>{error}</div>}
          
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputGroup}>
              <label htmlFor="password">New Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPasswordValue(e.target.value)}
                required
                minLength="8"
                placeholder="Enter your new password"
              />
            </div>
            
            <div className={styles.inputGroup}>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                minLength="8"
                placeholder="Confirm your new password"
              />
            </div>
            
            <button 
              type="submit" 
              className={`${styles.button} ${loading ? styles.loading : ''}`}
              disabled={loading}
            >
              {loading ? 'Setting Password...' : 'Set Password'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPassword; 