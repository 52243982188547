import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';

const SubscriptionRedirect = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const email = searchParams.get('email');
    const error = searchParams.get('message');

    if (error === 'subscription_exists') {
      navigate('/subscription-error', { 
        state: { email, type: 'active_subscription' }
      });
    } else if (error === 'account_exists') {
      navigate('/subscription-error', { 
        state: { email, type: 'existing_account' }
      });
    } else {
      navigate('/subscription-success', { 
        state: { email, type: 'new_account' }
      });
    }
  }, [navigate, searchParams]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h2 className="text-xl font-semibold mb-4">Processing...</h2>
        <p>Please wait while we redirect you.</p>
      </div>
    </div>
  );
};

export default SubscriptionRedirect; 