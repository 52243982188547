import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { login, getCurrentUser } from '../services/authService';
import styles from '../styles/LoginPage.module.css';
import axios from 'axios';

const LoginPage = () => {
  console.log('API URL:', process.env.REACT_APP_API_URL);
  
  const [formData, setFormData] = useState({
    username_or_email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const justRegistered = new URLSearchParams(location.search).get('registered');

  useEffect(() => {
    const user = getCurrentUser();
    if (user?.access) {
      navigate('/vip/tips');
    }
  }, [navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    // Clear error when user starts typing
    if (error) setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await axios.post('/api/token/', {
        username_or_email: formData.username_or_email.toLowerCase().trim(),
        password: formData.password
      });

      if (response.data.access) {
        // Store tokens
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        
        // Set the default Authorization header
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
        
        try {
          // Verify token works
          const userCheck = await axios.get('/api/subscription/check/');
          
          // Store user data
          localStorage.setItem('user', JSON.stringify(userCheck.data));
          
          // Get the base URL
          const baseUrl = window.location.origin;
          
          // Redirect with full URL
          window.location.href = `${baseUrl}/vip/tips`;
        } catch (verifyError) {
          console.error('Token verification failed:', verifyError);
          setError('Login successful but session verification failed. Please try again.');
          localStorage.clear();
        }
      }
    } catch (error) {
      console.error('Login error details:', {
        status: error.response?.status,
        data: error.response?.data,
        message: error.message
      });

      if (error.response?.data?.detail) {
        setError(error.response.data.detail);
      } else {
        setError('Login failed. Please check your credentials and try again.');
      }
      
      // Clear any partial auth data
      localStorage.clear();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.title}>Login to Your Account</h2>
        {error && <p className={styles.error}>{error}</p>}
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label className={styles.label}>Email or Username</label>
            <input
              className={styles.input}
              type="text"
              name="username_or_email"
              value={formData.username_or_email}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                username_or_email: e.target.value
              }))}
              placeholder="Enter your email"
              disabled={loading}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Password</label>
            <input
              className={styles.input}
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your password"
              disabled={loading}
              required
            />
          </div>
          <button 
            type="submit" 
            className={styles.button}
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        <p className={styles.forgotPassword}>
          <Link to="/forgot-password">Forgot Password?</Link>
        </p>
        {justRegistered && (
          <div className={styles.success}>
            Registration successful! Please check your email to verify your account.
          </div>
        )}
      </div>
    </>
  );
};

export default LoginPage;