import React, { useState } from 'react';
import styles from '../styles/MembershipPage.module.css';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';

const MembershipPage = () => {
  const [email, setEmail] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const tiers = [
    {
      name: 'Silver',
      price: '£5',
      period: 'per month',
      value: 'silver',
      features: [
        'NAP of the day',
        'Email Notifications',
      ]
    },
    {
      name: 'Gold',
      price: '£10',
      period: 'per month',
      value: 'gold',
      features: [
        'NAP of the day',
        'All Premium Tips',
        'Email Notifications',
        'Detailed Statistics (coming soon)',
      ]
    }
  ];

  const handleEmailChange = async (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setError(null);
    
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(emailValue);
    setIsEmailValid(isValid);
  
    // If email is valid, check if it exists
    if (isValid) {
      setIsLoading(true);
      try {
        const response = await axios.post('/api/subscription/validate-email/', { email: emailValue });
        if (response.data.hasSubscription) {
          setError(<>
            Account already exists. Please <a href="/login" className={styles.errorLink}>sign in</a> to manage your subscription.
          </>);
          setIsEmailValid(false);
        }
      } catch (error) {
        console.error('Error:', error);
        setError('An error occurred. Please try again.');
        setIsEmailValid(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = async (e, planValue) => {
    e.preventDefault();
    if (!isEmailValid) return;

    setIsLoading(true);
    try {
      const checkoutResponse = await axios.post('/api/subscription/create-checkout-session/', {
        email,
        plan: planValue
      });
      window.location.href = checkoutResponse.data.url;
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.membershipContainer}>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <LoadingSpinner text="Checking email..." />
        </div>
      )}
      
      <h1 className={styles.mainTitle}>Choose Your Membership Plan</h1>
      {error && <div className={styles.errorMessage}>{error}</div>}
      
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your email"
          required
          className={styles.emailInput}
        />
      </form>

      <div className={styles.tiersGrid}>
        {tiers.map((tier) => (
          <div 
            key={tier.value}
            className={`${styles.tierCard} 
              ${selectedPlan === tier.value ? styles.selected : ''} 
              ${(!isEmailValid || isLoading) ? styles.disabled : ''}`}
            data-tier={tier.value}
          >
            {tier.value === 'gold' && (
              <div className={styles.popularBadge}>Most Popular</div>
            )}
            <h2 className={styles.tierName}>{tier.name}</h2>
            <div className={styles.price}>{tier.price}</div>
            <ul className={styles.features}>
              {tier.features.map((feature, index) => (
                <li key={index}>
                  <span className={styles.checkmark}>✓</span> {feature}
                </li>
              ))}
            </ul>
            <button 
              className={`${styles.selectButton}`}
              disabled={!isEmailValid || isLoading}
              onClick={(e) => {
                e.preventDefault();
                if (isEmailValid && !isLoading) {
                  handleSubmit(e, tier.value);
                }
              }}
            >
              {isLoading ? 'Please wait...' : 'Subscribe'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MembershipPage; 