import { STAKES } from '../constants/stakes';

export const calculateProfitLoss = (tips) => {
    let totalProfit = 0;
    

    tips.forEach(tip => {
        // Normalize the type to handle different formats
        const normalizedType = tip.type.toUpperCase().replace(/ /g, '_');
        const stake = STAKES[Object.keys(STAKES).find(k => k.toUpperCase().replace(/ /g, '_') === normalizedType)] || 0;
        
        switch (tip.result) {
            case 'WON':
                if (normalizedType === 'EACH_WAY_PICK') {
                    // Split 0.5pt stake into win and place parts
                    const winStake = stake / 2;  // 0.25pt
                    const placeStake = stake / 2; // 0.25pt
                    
                    // Win part (0.25pt at full odds)
                    const winProfit = (winStake * tip.odds) - winStake;
                    
                    // Place part (0.25pt at 1/4 odds)
                    const placeOdds = ((tip.odds - 1) / 4) + 1;
                    const placeProfit = (placeStake * placeOdds) - placeStake;
                    
                    totalProfit += winProfit + placeProfit;
                } else {
                    const profit = (stake * tip.odds) - stake;
                    totalProfit += profit;
                }
                break;

            case 'PLACED':
                if (normalizedType === 'EACH_WAY_PICK') {
                    // Only place part wins (0.25pt stake at 1/4 odds)
                    const placeStake = stake / 2;
                    const placeOdds = ((tip.odds - 1) / 4) + 1;
                    const placeProfit = (placeStake * placeOdds) - placeStake;
                    
                    // Lose the win part (0.25pt)
                    const winStake = stake / 2;
                    
                    totalProfit += placeProfit - winStake;
                } else {
                    // Regular bets lose full stake on place
                    totalProfit -= stake;
                }
                break;

            case 'LOST':
                // All bet types lose full stake
                totalProfit -= stake;
                break;

            case 'NR':
                // Stake returned, no profit/loss
                totalProfit += 0;
                break;

            default:
                // For pending or unknown results
                totalProfit += 0;
        }
    });

    return {
        totalProfit,
        profitInPoints: totalProfit
    };
};

// Helper function to calculate individual tip P/L
export const calculateTipProfitLoss = (tip) => {
    const normalizedType = tip.type.toUpperCase().replace(/ /g, '_');
    const stake = STAKES[Object.keys(STAKES).find(k => k.toUpperCase().replace(/ /g, '_') === normalizedType)] || 0;
    let profit = 0;

    switch (tip.result) {
        case 'WON':
            if (normalizedType === 'EACH_WAY_PICK') {
                // Win part: 0.25pt at full odds
                const winStake = (stake / 2);
                profit += (winStake * tip.odds) - winStake;
                
                // Place part: 0.25pt at 1/4 odds
                const placeStake = (stake / 2);
                const placeOdds = ((tip.odds - 1) / 4) + 1;
                profit += (placeStake * placeOdds) - placeStake;
            } else {
                // Regular win bet
                profit += (stake * tip.odds) - stake;
            }
            break;

        case 'PLACED':
            if (normalizedType === 'EACH_WAY_PICK') {
                // For Each Way bets, PLACED means we get the place odds
                const placeOdds = ((tip.odds - 1) / 4) + 1;
                profit += (stake * placeOdds) - stake;
            } else {
                // Regular bets lose full stake on place
                profit -= stake;
            }
            break;

        case 'LOST':
            // All bet types lose full stake
            profit -= stake;
            break;

        case 'NR':
            // Stake returned, no profit/loss
            profit += 0;
            break;

        default:
            // For pending or unknown results
            profit += 0;
    }

    return {
        profit,
        profitInPoints: profit
    };
};