import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import styles from '../styles/VipPage.module.css';
import axios from 'axios';
import html2canvas from 'html2canvas';
import logo from '../logo2.png';

const VipPage = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [userTier, setUserTier] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const generateResultsImage = async () => {
    const container = document.createElement('div');
    container.style.width = '1080px';
    container.style.height = '1080px';
    container.style.backgroundColor = '#004225';
    container.style.padding = '40px';
    container.style.boxSizing = 'border-box';
    container.style.position = 'fixed';
    container.style.left = '-9999px';
    
    try {
      const response = await axios.get(`${API_URL}/api/tips/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      
      const today = new Date().toISOString().split('T')[0];
      const todaysTips = response.data.filter(tip => tip.date === today);
      
      // Calculate daily profits
      const dailyProfits = todaysTips.reduce((profits, tip) => {
        if (tip.result === 'PENDING' || tip.result === 'NR') return profits;
        
        // £10 win calculation
        let winProfit = 0;
        if (tip.result === 'WON') {
          winProfit = (10 * Number(tip.odds)) - 10;
        } else {
          winProfit = -10;
        }
        
        // £5 E/W calculation (match the utility function exactly)
        let ewProfit = 0;
        const odds = Number(tip.odds);
        
        if (tip.result === 'WON') {
          // Win part: £5 at full odds
          ewProfit += (5 * odds) - 5;
          // Place part: £5 at 1/4 odds
          ewProfit += (5 * ((odds - 1) / 4 + 1)) - 5;
        } else if (tip.result === 'PLACED') {
          // Lose win part
          ewProfit -= 5;
          // Win place part at 1/4 odds
          ewProfit += (5 * ((odds - 1) / 4 + 1)) - 5;
        } else if (tip.result === 'LOST') {
          ewProfit -= 10; // Lose both parts
        }
        
        return {
          win: profits.win + winProfit,
          ew: profits.ew + ewProfit
        };
      }, { win: 0, ew: 0 });

      // Separate NAP from other tips
      const napTip = todaysTips.find(tip => tip.type === 'NAP');
      const regularTips = todaysTips.filter(tip => tip.type !== 'NAP');
      
      // Determine grid columns based on number of tips
      const gridColumns = regularTips.length > 6 ? 3 : 2;
      
      container.innerHTML = `
        <div style="height: 100%; display: flex; flex-direction: column;">
          <div style="text-align: center; margin-bottom: 30px;">
            <img src="${logo}" alt="Mister Tipster Logo" style="height: 100px; width: auto;" crossorigin="anonymous"/>
            <h2 style="color: #FFD700; font-size: 32px; margin: 10px 0 0 0;">${new Date().toLocaleDateString('en-GB', { 
              weekday: 'long', 
              day: 'numeric', 
              month: 'long', 
              year: 'numeric' 
            })}</h2>
          </div>
          
          <div style="flex: 1; display: flex; flex-direction: column; gap: 20px; ${gridColumns === 2 ? 'margin: 0 100px;' : ''}">
            ${napTip ? `
              <div style="
                padding: 20px;
                background: rgba(255, 215, 0, 0.1);
                border-radius: 8px;
                border: 2px solid #FFD700;
                width: 100%;
              ">
                <div style="
                  background: #FFD700;
                  color: #004225;
                  display: inline-block;
                  padding: 4px 10px;
                  border-radius: 4px;
                  font-weight: bold;
                  font-size: 14px;
                  margin-bottom: 8px;
                ">
                  NAP of the Day
                </div>
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div>
                    <div style="font-size: 24px; font-weight: bold; color: white; margin-bottom: 6px;">
                      ${napTip.horse}
                    </div>
                    <div style="color: #cccccc; font-size: 16px;">
                      ${napTip.location.toUpperCase()} - ${napTip.odds}
                    </div>
                  </div>
                  <div style="
                    padding: 6px 16px;
                    border-radius: 4px;
                    font-weight: bold;
                    font-size: 16px;
                    background: ${
                      napTip.result === 'WON' ? '#00a651' : 
                      napTip.result === 'LOST' ? '#ff4444' : 
                      napTip.result === 'PLACED' ? '#FFD700' : 
                      'rgba(255,255,255,0.2)'
                    };
                    color: ${napTip.result === 'PLACED' ? '#004225' : 'white'};
                  ">
                    ${napTip.result || 'PENDING'}
                  </div>
                </div>
              </div>
            ` : ''}

            <div style="
              display: grid; 
              grid-template-columns: repeat(${gridColumns}, 1fr); 
              gap: 15px;
              width: 100%;
            ">
              ${regularTips.map(tip => `
                <div style="
                  padding: ${gridColumns === 2 ? '20px' : '15px'};
                  background: rgba(255,255,255,0.1);
                  border-radius: 8px;
                ">
                  <div style="font-size: ${gridColumns === 2 ? '22px' : '20px'}; font-weight: bold; color: white; margin-bottom: 4px;">
                    ${tip.horse}
                  </div>
                  <div style="color: #cccccc; font-size: ${gridColumns === 2 ? '16px' : '14px'}; margin-bottom: 8px;">
                    ${tip.location.toUpperCase()} - ${tip.type} - ${tip.odds}
                  </div>
                  <div style="
                    display: inline-block;
                    padding: 4px 12px;
                    border-radius: 4px;
                    font-weight: bold;
                    font-size: ${gridColumns === 2 ? '16px' : '14px'};
                    background: ${
                      tip.result === 'WON' ? '#00a651' : 
                      tip.result === 'LOST' ? '#ff4444' : 
                      tip.result === 'PLACED' ? '#FFD700' : 
                      'rgba(255,255,255,0.2)'
                    };
                    color: ${tip.result === 'PLACED' ? '#004225' : 'white'};
                  ">
                    ${tip.result || 'PENDING'}
                  </div>
                </div>
              `).join('')}
            </div>
          </div>
          
          <div style="
            border-top: 1px solid rgba(255,215,0,0.3);
            margin-top: 20px;
            padding-top: 20px;
            display: flex;
            justify-content: space-around;
            align-items: center;
          ">
            <div style="text-align: center;">
              <div style="color: #FFD700; font-size: 18px; margin-bottom: 8px;">£10 Win Profit/Loss</div>
              <div style="
                font-size: 24px;
                font-weight: bold;
                color: ${dailyProfits.win >= 0 ? '#00a651' : '#ff4444'};
              ">
                ${dailyProfits.win >= 0 ? '+' : ''}£${dailyProfits.win.toFixed(2)}
              </div>
            </div>
            
            <div style="text-align: center;">
              <div style="color: #FFD700; font-size: 18px; margin-bottom: 8px;">£5 E/W Profit/Loss</div>
              <div style="
                font-size: 24px;
                font-weight: bold;
                color: ${dailyProfits.ew >= 0 ? '#00a651' : '#ff4444'};
              ">
                ${dailyProfits.ew >= 0 ? '+' : ''}£${dailyProfits.ew.toFixed(2)}
              </div>
            </div>
          </div>
          
          <div style="text-align: center; color: #FFD700; font-size: 24px; margin-top: 20px;">
            www.mistertipster.co.uk
          </div>
        </div>
      `;

      document.body.appendChild(container);

      const canvas = await html2canvas(container, {
        width: 1080,
        height: 1080,
        scale: 2,
        backgroundColor: '#004225',
        logging: true,
        useCORS: true,
        allowTaint: true,
        imageTimeout: 0,
        onclone: function(clonedDoc) {
          const img = clonedDoc.querySelector('img');
          if (img) {
            img.crossOrigin = 'anonymous';
            img.src = logo;
          }
        }
      });

      const link = document.createElement('a');
      link.download = `mistertipster-results-${today}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();

    } catch (error) {
      console.error('Error generating image:', error);
    } finally {
      document.body.removeChild(container);
    }
  };

  const generateNAPStatsImage = async () => {
    const container = document.createElement('div');
    container.style.width = '1080px';
    container.style.height = '1080px';
    container.style.backgroundColor = '#004225';
    container.style.padding = '40px';
    container.style.boxSizing = 'border-box';
    container.style.position = 'fixed';
    container.style.left = '-9999px';
    
    let containerAdded = false;
    
    try {
      const response = await axios.get(`${API_URL}/api/tips/public-results/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      
      const napTips = response.data.filter(tip => tip.type === 'NAP');
      const completedTips = napTips.filter(tip => tip.result !== 'PENDING' && tip.result !== 'NR');
      
      const winners = completedTips.filter(tip => tip.result === 'WON');
      const winRate = ((winners.length / completedTips.length) * 100).toFixed(1);
      
      const allTimeProfit = completedTips.reduce((total, tip) => {
        if (tip.result === 'WON') {
          return total + ((10 * Number(tip.odds)) - 10);
        }
        return total - 10;
      }, 0);

      // Calculate previous month's profits
      const today = new Date();
      const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      
      const previousMonthTips = completedTips.filter(tip => {
        const tipDate = new Date(tip.date);
        return tipDate >= firstDayOfPreviousMonth && tipDate <= lastDayOfPreviousMonth;
      });
      
      const monthProfit = previousMonthTips.reduce((total, tip) => {
        if (tip.result === 'WON') {
          return total + ((10 * Number(tip.odds)) - 10);
        }
        return total - 10;
      }, 0);

      // Get previous month name
      const previousMonthName = firstDayOfPreviousMonth.toLocaleString('en-GB', { month: 'long' });

      container.innerHTML = `
        <div style="
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          font-family: Arial, sans-serif;
          color: white;
          text-align: center;
        ">
          <img src="${logo}" alt="Mister Tipster Logo" style="height: 120px; width: auto; margin: 20px 0;" crossorigin="anonymous"/>
          
          <div style="
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 40px;
            margin: 40px 0;
            width: 100%;
          ">
            <div style="
              padding: 30px;
              background: rgba(255,215,0,0.1);
              border-radius: 12px;
              border: 2px solid #FFD700;
            ">
              <h3 style="color: #FFD700; font-size: 24px; margin: 0 0 20px 0;">${previousMonthName.toUpperCase()} NAP TIPS</h3>
              <div style="font-size: 48px; font-weight: bold; color: #FFD700;">£${monthProfit.toFixed(2)}</div>
              <div style="font-size: 16px; margin-top: 10px;">Profit to £10 Stakes</div>
            </div>
            
            <div style="
              padding: 30px;
              background: rgba(255,215,0,0.1);
              border-radius: 12px;
              border: 2px solid #FFD700;
            ">
              <h3 style="color: #FFD700; font-size: 24px; margin: 0 0 20px 0;">ALL-TIME NAP PROFITS</h3>
              <div style="font-size: 48px; font-weight: bold; color: #FFD700;">£${allTimeProfit.toFixed(2)}</div>
              <div style="font-size: 16px; margin-top: 10px;">Profit to £10 Stakes</div>
            </div>
            
            <div style="
              padding: 30px;
              background: rgba(255,215,0,0.1);
              border-radius: 12px;
              border: 2px solid #FFD700;
            ">
              <h3 style="color: #FFD700; font-size: 24px; margin: 0 0 20px 0;">NAP STRIKE RATE</h3>
              <div style="font-size: 48px; font-weight: bold; color: #FFD700;">${winRate}%</div>
              <div style="font-size: 16px; margin-top: 10px;">Win Rate</div>
            </div>
          </div>

          <div style="
            background: rgba(255,215,0,0.1);
            border-radius: 12px;
            border: 2px solid #FFD700;
            padding: 30px;
            width: 80%;
            margin-bottom: 20px;
          ">
            <div style="font-size: 28px; color: #FFD700; margin-bottom: 15px;">Data-Driven Analytics & In-Depth Research</div>
            <div style="font-size: 20px;">Our algorithms ensure you stay ahead in the game.</div>
          </div>

          <div style="
            background: rgba(255,215,0,0.1);
            border-radius: 12px;
            border: 2px solid #FFD700;
            padding: 30px;
            width: 80%;
            margin-bottom: 40px;
          ">
            <div style="font-size: 32px; color: #FFD700; margin-bottom: 15px;">Start Your Free Trial Today</div>
            <div style="font-size: 24px;">3 Days Free, Then Just £5/month</div>
            <div style="font-size: 20px; margin-top: 10px;">Be in profit vs. your monthly subscription fee!</div>
          </div>

          <div style="font-size: 24px; color: #FFD700;">
            www.mistertipster.co.uk
          </div>
        </div>
      `;

      document.body.appendChild(container);
      containerAdded = true;

      const canvas = await html2canvas(container, {
        width: 1080,
        height: 1080,
        scale: 2,
        backgroundColor: '#004225',
        logging: true,
        useCORS: true,
        allowTaint: true,
        imageTimeout: 0,
        onclone: function(clonedDoc) {
          const img = clonedDoc.querySelector('img');
          if (img) {
            img.crossOrigin = 'anonymous';
            img.src = logo;
          }
        }
      });

      const link = document.createElement('a');
      link.download = `mistertipster-stats-${new Date().toISOString().split('T')[0]}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();

    } catch (error) {
      console.error('Error generating image:', error);
    } finally {
      if (containerAdded && container.parentNode) {
        document.body.removeChild(container);
      }
    }
  };

  useEffect(() => {
    const checkAccess = async () => {
      try {
        // First check if user is logged in
        if (!localStorage.getItem('access_token')) {
          navigate('/membership');
          return;
        }

        const user = JSON.parse(localStorage.getItem('user'));
        const isStaffMember = user?.is_staff || false;
        setIsAdmin(isStaffMember);

        // Check subscription status
        const response = await axios.get(`${API_URL}/api/subscription/check/`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        });

        // If user has free access or active subscription, allow access
        if (response.data.has_free_access) {
          setUserTier('gold');
        } else if (response.data.is_subscribed) {
          setUserTier(response.data.subscription_tier);
        } else {
          // If user is logged in but has no active subscription,
          // redirect to account page where they can resubscribe
          navigate('/account');
          return;
        }
      } catch (error) {
        console.error('Error checking access:', error);
        if (error.response?.status === 401) {
          navigate('/membership');
        } else {
          navigate('/account');
        }
      } finally {
        setLoading(false);
      }
    };

    checkAccess();
  }, [API_URL, navigate]);

  // Render loading state
  if (loading) return <LoadingSpinner />;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>VIP Dashboard</h1>
        {isAdmin && (
          <div className={styles.adminControls}>
            <button 
              onClick={generateResultsImage} 
              className={styles.downloadButton}
            >
              Download Results Image
            </button>
            <button 
              onClick={generateNAPStatsImage} 
              className={styles.downloadButton}
            >
              Download NAP Stats Image
            </button>
            <span className={styles.adminBadge}>
              Admin Access
            </span>
          </div>
        )}
      </div>
      
      <div className={styles.content}>
        <nav className={styles.sidebar}>
          <NavLink to="/vip/tips" className={({ isActive }) => isActive ? styles.activeLink : styles.link}>
            Today's Tips
          </NavLink>
          
          {(userTier === 'gold') && (
            <NavLink to="/vip/results" className={({ isActive }) => isActive ? styles.activeLink : styles.link}>
              Previous Results
            </NavLink>
          )}
        </nav>
        
        <main className={styles.main}>
          <Outlet context={{ isAdmin, userTier }} />
        </main>
      </div>
    </div>
  );
};

export default VipPage;