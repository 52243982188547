import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/NotFoundPage.module.css';

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.notFoundContainer}>
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>Sorry, the page you're looking for doesn't exist.</p>
            <button 
                className={styles.homeButton}
                onClick={() => navigate('/')}
            >
                Back to Home
            </button>
        </div>
    );
};

export default NotFoundPage; 