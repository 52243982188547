import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styles from '../styles/SubscriptionError.module.css';

const SubscriptionError = () => {
  const location = useLocation();
  const { email, type } = location.state || {};

  const renderContent = () => {
    if (type === 'active_subscription') {
      return (
        <>
          <h1>Active Subscription Found</h1>
          <p>The email address <strong>{email}</strong> already has an active subscription.</p>
          <div className={styles.actionButtons}>
            <Link to="/login" className={styles.primaryButton}>
              Login to Your Account
            </Link>
            <Link to="/reset-password" className={styles.secondaryButton}>
              Reset Password
            </Link>
          </div>
          <div className={styles.support}>
            <p>Need help? Contact <a href="mailto:support@mistertipster.co.uk">support@mistertipster.co.uk</a></p>
          </div>
        </>
      );
    }

    return (
      <>
        <h1>Existing Account Found</h1>
        <p>An account already exists for <strong>{email}</strong></p>
        <p>Please login to manage your subscription.</p>
        <div className={styles.actionButtons}>
          <Link to="/login" className={styles.primaryButton}>
            Login Here
          </Link>
          <Link to="/reset-password" className={styles.secondaryButton}>
            Reset Password
          </Link>
        </div>
        <div className={styles.support}>
          <p>Need help? Contact <a href="mailto:support@mistertipster.co.uk">support@mistertipster.co.uk</a></p>
        </div>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.errorCard}>
        {renderContent()}
      </div>
    </div>
  );
};

export default SubscriptionError; 