import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/CompleteAccountPage.module.css';

const CompleteAccountPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await axios.post('/api/accounts/complete-setup/', {
        token,
        password
      });
      
      // Redirect to dashboard after successful setup
      navigate('/dashboard');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to complete account setup');
    }
  };

  return (
    <div className={styles.setupContainer}>
      <div className={styles.setupCard}>
        <h1>Complete Your Account Setup</h1>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label>Create Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              minLength="8"
              required
            />
          </div>

          <div className={styles.inputGroup}>
            <label>Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              minLength="8"
              required
            />
          </div>

          {error && <div className={styles.error}>{error}</div>}

          <button type="submit" className={styles.submitButton}>
            Complete Setup
          </button>
        </form>
      </div>
    </div>
  );
};

export default CompleteAccountPage; 