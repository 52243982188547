import React, { useState, useEffect } from 'react';
import styles from '../styles/TipsPage.module.css';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import { Link, useOutletContext } from 'react-router-dom';

const TipsPage = () => {
    const { userTier } = useOutletContext();
    const [tips, setTips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedTips, setExpandedTips] = useState(new Set());
    const [activeFilter, setActiveFilter] = useState('all');
    const [isAdmin, setIsAdmin] = useState(false);
    const [oddsFormat, setOddsFormat] = useState('decimal');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTier] = useState('gold'); // Default to gold for upgrades
    
    const API_URL = process.env.REACT_APP_API_URL;

    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };

    const parseRaceTime = (timeStr) => {
        // If it's already a full datetime string
        if (timeStr.includes('T')) {
            return new Date(timeStr);
        }
        
        // If it's just a time (HH:MM), add today's date
        const today = formatDate(new Date());
        return new Date(`${today}T${timeStr}`);
    };

    const parseTimeToMinutes = (timeStr) => {
        try {
            // Handle cases where timeStr might be undefined or in wrong format
            if (!timeStr) return 0;
            
            // Extract time part (assumes format "Location - HH:MM")
            const timePart = timeStr.includes(' - ') 
                ? timeStr.split(' - ')[1] 
                : timeStr;
            
            // Convert HH:MM to minutes
            const [hours, minutes] = timePart.split(':').map(Number);
            return (hours * 60) + minutes;
        } catch (error) {
            console.error('Error parsing time:', timeStr, error);
            return 0; // Return 0 for invalid times
        }
    };

    // Separate NAP and keep other tips in their original order
    const napTip = tips.find(tip => tip.type === 'NAP');
    const regularTips = tips.filter(tip => tip.type !== 'NAP');

    useEffect(() => {
        const userStr = localStorage.getItem('user');
        if (userStr) {
            try {
                const user = JSON.parse(userStr);
                setIsAdmin(user.is_staff === true);
            } catch (error) {
                console.error('Error parsing user data:', error);
                setIsAdmin(false);
            }
        }
    }, []);

    // Fetch user preferences on load
    useEffect(() => {
        const userStr = localStorage.getItem('user');
        if (userStr) {
            const user = JSON.parse(userStr);
            setOddsFormat(user.odds_format || 'decimal');
        }
    }, []);

    const filterTipsByTier = (tips) => {
        // Gold users see all tips
        if (userTier === 'gold') {
            return tips;
        }
        
        // Silver users only see the NAP
        if (userTier === 'silver') {
            return tips.filter(tip => tip.type === 'NAP');
        }
        
        return [];
    };

    const getFilteredTips = () => {
        const otherTips = tips.filter(tip => tip.type !== 'NAP');
        if (activeFilter === 'all') return otherTips;
        return otherTips.filter(tip => tip.type === activeFilter);
    };

    const formatRaceTime = (dateTimeString) => {
      // First check if we're getting a full datetime string
      if (dateTimeString.includes('T')) {
        return dateTimeString.split('T')[1].substring(0, 5); // Get HH:MM from ISO string
      }
      
      // If we're getting just a time string
      if (dateTimeString.includes(':')) {
        return dateTimeString.substring(0, 5); // Get HH:MM from time string
      }
      
      // If we're getting date-time in a different format
      const parts = dateTimeString.split(' ');
      return parts.length > 1 ? parts[1].substring(0, 5) : dateTimeString;
    };

    const getAuthHeaders = () => {
      try {
          // Try both possible token storage methods
          const accessToken = localStorage.getItem('access_token');
          const userStr = localStorage.getItem('user');
          
          if (accessToken) {
              return {
                  'Authorization': `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
              };
          }
  
          if (userStr) {
              const user = JSON.parse(userStr);
              if (user?.access) {
                  return {
                      'Authorization': `Bearer ${user.access}`,
                      'Content-Type': 'application/json',
                  };
              }
          }
  
          console.error('No valid token found in either storage location');
          return null;
      } catch (error) {
          console.error('Error getting auth headers:', error);
          return null;
      }
  };
  
  useEffect(() => {
    const fetchTips = async () => {
      
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          throw new Error('No auth token found');
        }

        const response = await axios.get(`${API_URL}/api/tips/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        
        // Filter for today's tips
        const today = new Date().toISOString().split('T')[0];
        const todaysTips = response.data.filter(tip => {
          const tipDate = new Date(tip.date).toISOString().split('T')[0];
          return tipDate === today;
        });        
        // Filter based on user tier
        const filteredTips = filterTipsByTier(todaysTips);
        
        setTips(filteredTips);
      } catch (err) {
        console.error('Error fetching tips:', err);
        setError(err.message || 'Failed to fetch tips');
      } finally {
        setLoading(false);
      }
    };

    if (userTier) {
      fetchTips();
    }
  }, [API_URL, userTier]);

    const toggleTip = (tipId) => {
      setExpandedTips(prev => {
        const newSet = new Set(prev);
        if (newSet.has(tipId)) {
          newSet.delete(tipId);
        } else {
          newSet.add(tipId);
        }
        return newSet;
      });
    };

    // Capitalize the first letter of each word in a string
    const capitalizeWords = (str) => {
      return str.split(' ').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join(' ');
    };

    // Add tier-specific messaging
    const renderTierMessage = () => {
      if (userTier === 'silver') {
        return (
          <div className={styles.tierMessage}>
            <h3>Silver Membership</h3>
            <p>Upgrade to Gold to access all premium tips!</p>
            <button 
              className={styles.upgradeButton}
              onClick={() => window.location.href = '/account'}
            >
              Upgrade Now
            </button>
          </div>
        );
      }
      return null;
    };

    const formatTipType = (type) => {
        switch (type) {
            case 'NAP':
                return 'NAP';
            case 'SOLID PICK':
                return 'SOLID';
            case 'VALUE PICK':
                return 'VALUE';
            case 'EACH WAY PICK':
                return 'E/W';
            default:
                return type;
        }
    };

    const formatOdds = (decimal) => {
        
        if (oddsFormat === 'decimal') {
            return Number(decimal).toFixed(2);
        }
        
        // Convert to fractional
        const decimalOdds = Number(decimal);
        const tolerance = 0.0001;
        
        for (let denominator = 1; denominator <= 100; denominator++) {
            const numerator = Math.round(decimalOdds * denominator - denominator);
            if (Math.abs(decimalOdds - (numerator + denominator) / denominator) < tolerance) {
                return `${numerator}/${denominator}`;
            }
        }
        
        return Number(decimal).toFixed(2);
    };

    const renderTip = (tip, isNap = false) => (
        <div key={tip.id} className={`${styles.tipRow} ${isNap ? styles.napRow : ''} ${tip.result ? styles[tip.result.toLowerCase()] : ''}`}>
            <div className={styles.tipType}>
                {formatTipType(tip.type)}
            </div>
            
            <div className={styles.resultTag}>
                {tip.result || 'Pending'}
            </div>
            
            <div className={styles.silksImage}>
                {tip.image_link ? (
                    <img 
                        src={tip.image_link} 
                        alt="Jockey silks" 
                        className={styles.silks}
                    />
                ) : (
                    <div className={styles.noSilks}>
                        <i className="fas fa-user-astronaut"></i>
                    </div>
                )}
            </div>
            
            <div className={styles.mainSection}>
                <div className={styles.horseName}>{tip.horse}</div>
                <div className={styles.raceInfo}>
                    {capitalizeWords(tip.location)} - {formatRaceTime(tip.race_time)}
                </div>
            </div>

            <div className={styles.odds}>{formatOdds(tip.odds)}</div>
            
            <button 
                className={`${styles.analysisButton} ${expandedTips.has(tip.id) ? styles.expanded : ''}`}
                onClick={() => toggleTip(tip.id)}
                aria-label={expandedTips.has(tip.id) ? "Hide details" : "Show details"}
            >
                <i className={`fas ${expandedTips.has(tip.id) ? 'fa-times' : 'fa-info'}`}></i>
            </button>
            
            {expandedTips.has(tip.id) && (
                <div className={styles.analysisPanel}>
                    <div className={styles.analysisContent}>
                        <div className={styles.riderAndStats}>
                            <div className={styles.riderInfo}>
                                <div className={styles.infoColumn}>
                                    <h4>Jockey / Trainer</h4>
                                    <p>{tip.jockey} / {tip.trainer}</p>
                                </div>
                            </div>
                            
                            <div className={styles.horseStats}>
                                <div className={styles.statsHeader}>
                                    <span>Weight</span>
                                    <span>Age</span>
                                    <span>Form</span>
                                </div>
                                <div className={styles.statsValues}>
                                    <span>{tip.weight || '-'}</span>
                                    <span>{tip.age || '-'}</span>
                                    <span>{tip.form || '-'}</span>
                                </div>
                            </div>
                        </div>

                        <div className={styles.analysisSection}>
                            <h4>Analysis</h4>
                            <p>{tip.reasons}</p>
                        </div>
                        
                        <div className={styles.analysisSection}>
                            <h4>Racing Post Comment</h4>
                            <p>{tip.racing_post_comment}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    const toggleOddsFormat = async () => {
        try {
            const newFormat = oddsFormat === 'decimal' ? 'fractional' : 'decimal';
            
            const token = localStorage.getItem('access_token');
            if (!token) {
                throw new Error('No auth token found');
            }

            const response = await axios.post(
                `${API_URL}/api/accounts/update-odds-format/`,
                { odds_format: newFormat },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
                        
            // Update local state
            setOddsFormat(newFormat);
            
            // Update user in localStorage
            const userStr = localStorage.getItem('user');
            if (userStr) {
                const user = JSON.parse(userStr);
                user.odds_format = newFormat;
                localStorage.setItem('user', JSON.stringify(user));
            }

            // Force re-render of tips
            setTips([...tips]);
        } catch (error) {
            console.error('Error updating odds format:', error);
        }
    };

    const handleUpgrade = async () => {
        try {
            setIsLoading(true);
            const response = await axios.post(
                `${API_URL}/api/subscription/resubscribe/`, 
                { tier: 'gold' }, // Default to gold tier for upgrades
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            if (response?.data?.url) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            console.error('Failed to create upgrade session:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Update the UpgradeMessage component
    const UpgradeMessage = () => (
        <div className={styles.upgradeContainer}>
            <div className={styles.upgradeContent}>
                <h3>Unlock All Daily Tips!</h3>
                <p>Upgrade to Gold to access:</p>
                <ul>
                    <li>✓ All Daily Tips</li>
                    <li>✓ Detailed Analysis</li>
                    <li>✓ Performance Analytics</li>
                    <li>✓ Historical Results</li>
                </ul>
                <button 
                    className={styles.upgradeButton}
                    onClick={handleUpgrade}
                    disabled={isLoading}
                >
                    {isLoading ? 'Processing...' : 'Upgrade Now'}
                </button>
            </div>
        </div>
    );

    const formatDisplayDate = (date) => {
        return new Date(date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    return (
        <div className={styles.contentWrapper}>
            <div className={styles.oddsFormatToggle}>
                <span className={styles.toggleLabel}>Decimal</span>
                <label className={styles.toggleSwitch}>
                    <input
                        type="checkbox"
                        checked={oddsFormat === 'fractional'}
                        onChange={toggleOddsFormat}
                    />
                    <span className={styles.slider}></span>
                </label>
                <span className={styles.toggleLabel}>Fractional</span>
            </div>
            {loading && <LoadingSpinner text="Loading tips..." />}
            {error && <div className={styles.error}>{error}</div>}
            {!loading && !error && (
                <>
                    <h1 className={styles.dateHeader}>
                        {formatDisplayDate(new Date())}
                    </h1>
                    
                    {tips.length === 0 ? (
                        <div className={styles.noTips}>
                            <h2>No Tips Available Yet</h2>
                            <p>Tips are usually ready by 10:30-11am!</p>
                        </div>
                    ) : (
                        <>
                            <section className={styles.tipsSection}>
                                {/* NAP always first */}
                                {napTip && renderTip(napTip, true)}
                                
                                {/* Other tips in their original order */}
                                {(userTier === 'gold') && 
                                    regularTips.map(tip => renderTip(tip))
                                }
                            </section>
                            {userTier === 'silver' && <UpgradeMessage />}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default TipsPage;