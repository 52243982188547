import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from '../styles/AccountSettings.module.css';
import { getAuthHeader, hasValidToken, logout } from '../services/authService';
import axios from 'axios';

const AccountSettings = () => {
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTier, setSelectedTier] = useState('silver');
    const navigate = useNavigate();

    const handleUnauthorized = () => {
        // Clear all auth data
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
        // Redirect to login
        navigate('/login');
    };

    const checkSubscription = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/api/subscription/check/', {
                headers: getAuthHeader()
            });
            setSubscription(response.data);
        } catch (error) {
            if (error.response?.status === 401) {
                handleUnauthorized();
                return;
            }
            setError('Failed to load subscription status');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkSubscription();
    }, []);

    useEffect(() => {
        if (subscription?.subscription_tier) {
            setSelectedTier(subscription.subscription_tier);
        }
    }, [subscription]);

    const handleManageSubscription = async () => {
        try {
            setIsLoading(true);
            const response = await axios.post(
                '/api/subscription/portal/',
                {},
                { headers: getAuthHeader() }
            );
            if (response?.data?.url) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            if (error.response?.status === 404) {
                navigate('/membership');
            } else {
                setError('Failed to open subscription management portal. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleResubscribe = async () => {
        try {
            setIsLoading(true);
            const response = await axios.post('/api/subscription/resubscribe/', 
                { tier: selectedTier },
                { headers: getAuthHeader() }
            );
            if (response?.data?.url) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            setError('Failed to create resubscribe session. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    const formatTierName = (tier) => {
        return tier.charAt(0).toUpperCase() + tier.slice(1);
    };

    const getStatusDisplay = (subscription) => {
        if (subscription.has_free_access) {
            return (
                <div className={`${styles.statusBanner} ${styles.freeAccessBanner}`}>
                    <div className={styles.statusInfo}>
                        <span className={styles.statusLabel}>Account Status:</span>
                        <span className={styles.freeAccessStatus}>
                            Free Access
                        </span>
                    </div>
                    <p className={styles.freeAccessNote}>
                        You have been granted free access to our tips! Enjoy all the benefits of our service.
                    </p>
                </div>
            );
        }

        if (!subscription.is_subscribed || subscription.status === 'canceled') {
            return (
                <div className={styles.statusBanner}>
                    <div className={styles.statusInfo}>
                        <span className={styles.statusLabel}>Subscription Status:</span>
                        <span className={styles.cancelledStatus}>
                            Cancelled
                        </span>
                    </div>
                    <p className={styles.cancellationNote}>
                        Your subscription has been cancelled. You can reactivate your subscription 
                        using the button below to continue enjoying our VIP benefits.
                    </p>
                </div>
            );
        } else if (!subscription.auto_renew) {
            return (
                <div className={styles.statusBanner}>
                    <div className={styles.statusInfo}>
                        <span className={styles.statusLabel}>Subscription Status:</span>
                        <span className={styles.cancellingStatus}>
                            Active (Cancelling)
                        </span>
                    </div>
                    <p className={styles.cancellationNote}>
                        Your subscription will remain active until {formatDate(subscription.renewal_date)}, 
                        after which it will be cancelled. You can continue to enjoy all benefits until then.
                    </p>
                </div>
            );
        }
        return (
            <div className={styles.statusBanner}>
                <div className={styles.statusInfo}>
                    <span className={styles.statusLabel}>Subscription Status:</span>
                    <span className={styles.activeStatus}>Active</span>
                </div>
                <p className={styles.renewalNote}>
                    Your subscription will automatically renew on {formatDate(subscription.renewal_date)}
                </p>
            </div>
        );
    };

    const renderResubscribeSection = () => {
        if (subscription?.is_subscribed && subscription?.status !== 'canceled') {
            return null;
        }

        return (
            <div className={styles.tierSelection}>
                <h3>Choose Your Plan</h3>
                <div className={styles.tierOptions}>
                    <div 
                        className={`${styles.tierOption} ${selectedTier === 'silver' ? styles.selected : ''}`}
                        onClick={() => setSelectedTier('silver')}
                    >
                        <h4>Silver</h4>
                        <p className={styles.price}>£5/month</p>
                    </div>
                    <div 
                        className={`${styles.tierOption} ${selectedTier === 'gold' ? styles.selected : ''}`}
                        onClick={() => setSelectedTier('gold')}
                    >
                        <h4>Gold</h4>
                        <p className={styles.price}>£10/month</p>
                    </div>
                </div>
                <div className={styles.buttonGroup}>
                    <button 
                        onClick={handleResubscribe} 
                        className={`${styles.manageButton} ${styles.resubscribeButton}`}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Loading...' : `Subscribe to ${formatTierName(selectedTier)}`}
                    </button>
                </div>
            </div>
        );
    };

    const getTierAmount = (tier) => {
        const amounts = {
            'silver': 5,
            'gold': 10,
        };
        return amounts[tier] || 0;
    };

    const renderBenefits = (tier) => {
        const benefits = {
            'gold': [
                'NAP of the day',
                'All Premium Tips',
                'Email Notifications',
                'Detailed Statistics (coming soon)'
            ],
            'silver': [
                'NAP of the day',
                'Email Notifications'
            ]
        };

        if (!tier || !benefits[tier]) {
            return [].map((benefit, index) => (
                <li key={index}>{benefit}</li>
            ));
        }

        return benefits[tier].map((benefit, index) => (
            <li key={index}>{benefit}</li>
        ));
    };

    const getMembershipDisplay = (subscription) => {
        if (subscription.has_free_access) {
            return {
                type: 'Free Access Member',
                price: '£0/month',
                status: 'Active',
                renewalText: 'Free access granted'
            };
        }

        return {
            type: `${formatTierName(subscription.subscription_tier)} Member`,
            price: `£${getTierAmount(subscription.subscription_tier)}/month`,
            status: subscription.status,
            renewalText: subscription.auto_renew 
                ? `Your subscription will automatically renew on ${formatDate(subscription.renewal_date)}`
                : 'Your subscription will not auto-renew'
        };
    };

    return (
        <div className={styles.container}>
            <h1>Account Settings</h1>
            {error && <div className={styles.error}>{error}</div>}
            
            {loading ? (
                <div className={styles.loading}>Loading...</div>
            ) : subscription ? (
                <div className={styles.subscriptionCard}>
                    <div className={styles.userInfo}>
                        <h3>Account Details</h3>
                        <div className={styles.detailsGrid}>
                            <div className={styles.detailItem}>
                                <span className={styles.label}>Email:</span>
                                <span className={styles.value}>{subscription.user_email}</span>
                            </div>
                            <div className={styles.detailItem}>
                                <span className={styles.label}>Member Since:</span>
                                <span className={styles.value}>{formatDate(subscription.created_at)}</span>
                            </div>
                        </div>
                    </div>

                    {(() => {
                        const membershipInfo = getMembershipDisplay(subscription);
                        return (
                            <>
                                <h2>{membershipInfo.type}</h2>
                                <p className={styles.price}>{membershipInfo.price}</p>
                                
                                <div className={styles.statusBanner}>
                                    <div className={styles.statusInfo}>
                                        <span className={styles.statusLabel}>Status:</span>
                                        <span className={styles.activeStatus}>{membershipInfo.status}</span>
                                    </div>
                                    <p className={styles.renewalNote}>{membershipInfo.renewalText}</p>
                                </div>
                            </>
                        );
                    })()}

                    {(() => {
                        const membershipInfo = getMembershipDisplay(subscription);
                        return (
                            <>
                                {(() => {
                                    if (!subscription.has_free_access) {
                                        return (
                                            <div className={styles.subscriptionDetails}>
                                                <h3>Subscription Details</h3>
                                                <div className={styles.detailsGrid}>
                                                    <div className={styles.detailItem}>
                                                        <span className={styles.label}>
                                                            {!subscription.auto_renew ? 'Access Until:' : 'Next Billing Date:'}
                                                        </span>
                                                        <span className={styles.value}>{formatDate(subscription.renewal_date)}</span>
                                                    </div>
                                                    <div className={styles.detailItem}>
                                                        <span className={styles.label}>
                                                            {subscription.is_canceling ? 'Final Payment:' : 'Billing Amount:'}
                                                        </span>
                                                        <span className={styles.value}>£{getTierAmount(subscription.subscription_tier)}</span>
                                                    </div>
                                                    <div className={styles.detailItem}>
                                                        <span className={styles.label}>Payment Method:</span>
                                                        <span className={styles.value}>
                                                            {subscription.card_brand ? (
                                                                `${subscription.card_brand.toUpperCase()} •••• ${subscription.last4}`
                                                            ) : (
                                                                '•••• ****'
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={styles.detailItem}>
                                                        <span className={styles.label}>Auto-Renew:</span>
                                                        <span className={styles.value}>
                                                            {subscription.is_canceling ? 'Cancelled' : (subscription.auto_renew ? 'Enabled' : 'Disabled')}
                                                        </span>
                                                    </div>
                                                </div>
                                                
                                                <div className={styles.preferencesSection}>
                                                    <h3>Communication Preferences</h3>
                                                    <div className={styles.detailsGrid}>
                                                        <div className={styles.detailItem}>
                                                            <span className={styles.label}>Subscription Reminders:</span>
                                                            <span className={styles.value}>
                                                                {subscription.email_reminders ? 'Enabled' : 'Disabled'}
                                                            </span>
                                                        </div>
                                                        <div className={styles.detailItem}>
                                                            <span className={styles.label}>Marketing Emails:</span>
                                                            <span className={styles.value}>
                                                                {subscription.marketing_emails ? 'Enabled' : 'Disabled'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className={styles.buttonGroup}>
                                                    {(!subscription.is_subscribed || subscription.status === 'canceled') ? (
                                                        <>
                                                            {renderResubscribeSection()}
                                                        </>
                                                    ) : (
                                                        <button 
                                                            onClick={handleManageSubscription} 
                                                            className={styles.manageButton}
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? 'Loading...' : 'Manage Subscription'}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }
                                })()}

                                <div className={styles.tierBenefits}>
                                    <h3>
                                        {subscription.has_free_access 
                                            ? 'Your Free Access Benefits'
                                            : `Your ${formatTierName(subscription.subscription_tier)} Benefits`}
                                    </h3>
                                    <ul className={styles.benefitsList}>
                                        {renderBenefits(subscription.has_free_access ? 'gold' : subscription.subscription_tier)}
                                    </ul>
                                </div>
                            </>
                        );
                    })()}
                </div>
            ) : (
                <div className={styles.inactiveSubscription}>
                    <p className={styles.inactiveMessage}>
                        You currently don't have any subscription history.
                    </p>
                </div>
            )}
        </div>
    );
};

export default AccountSettings;