import axios from 'axios';
import api from './api';

const API_URL = process.env.REACT_APP_API_URL;

// Configure axios defaults
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

export const getAuthHeader = () => {
  const token = localStorage.getItem('access_token');
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

export const hasValidToken = () => {
  const token = localStorage.getItem('access_token');
  if (!token) return false;

  // Set default header for all future requests
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return true;
};

export const getCurrentUser = () => {
  try {
    const userStr = localStorage.getItem('user');
    const token = localStorage.getItem('access_token');
    
    if (!userStr || !token) {
      return null;
    }

    const user = JSON.parse(userStr);
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return {
        ...user,
        is_staff: user.is_staff || false
      };
    }
    
    // Clean up invalid data
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete axios.defaults.headers.common['Authorization'];
    return null;
  } catch (error) {
    console.error('Error getting current user:', error);
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete axios.defaults.headers.common['Authorization'];
    return null;
  }
};

export const login = async (usernameOrEmail, password) => {
  const loginUrl = `${API_URL}/api/token/`;
  
  try {
    const response = await axios.post(loginUrl, {
      username_or_email: usernameOrEmail,
      password: password
    });
    
    if (response.data.access) {
      const userData = {
        username: response.data.username,
        email: response.data.email,
        is_staff: response.data.is_staff || false,
        force_password_change: response.data.force_password_change || false
      };
      
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      localStorage.setItem('user', JSON.stringify(userData));
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.clear();
  // Remove auth header
  delete axios.defaults.headers.common['Authorization'];
  // Use full page refresh to clear all state
  window.location.href = '/login';
};

export const changePassword = async (newPassword) => {
  try {
    const response = await axios.post(`${API_URL}/api/accounts/change-password/`, {
      new_password: newPassword
    }, {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'application/json'
      }
    });
    
    // Update user data in localStorage to remove force_password_change flag
    const userData = JSON.parse(localStorage.getItem('user'));
    userData.force_password_change = false;
    localStorage.setItem('user', JSON.stringify(userData));
    
    return response.data;
  } catch (error) {
    console.error('Change password error:', error);
    throw error;
  }
};

export const setPassword = async (uid, token, password) => {
  try {
    const response = await api.post(`/api/accounts/set-password/${uid}/${token}/`, {
      password: password
    });
    return response.data;
  } catch (error) {
    console.error('Set password error:', error);
    throw error;
  }
};

export const getUserEmail = async (uid) => {
    try {
        const response = await api.get(`/api/accounts/get-email/${uid}/`);
        return response.data.email;
    } catch (error) {
        console.error('Error fetching email:', error);
        throw error;
    }
};