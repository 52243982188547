import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styles from '../styles/ProfitChart.module.css';
import axiosInstance from '../utils/axios';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ProfitChart = () => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfitData = async () => {
      try {
        const response = await axiosInstance.get('/api/tips/profit-chart/');
        const profitData = response.data;

        if (!profitData.length) {
          setChartData(null);
          return;
        }

        const labels = profitData.map(item => 
          new Date(item.date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short'
          })
        );

        const data = profitData.map(item => item.profit);

        setChartData({
          labels,
          datasets: [{
            label: 'Cumulative Profit (Points)',
            data,
            borderColor: '#ffd700',
            backgroundColor: 'rgba(255, 215, 0, 0.1)',
            fill: true,
            tension: 0.3,
            pointRadius: 3,
            pointHoverRadius: 6,
            pointBackgroundColor: '#ffd700',
            pointHoverBackgroundColor: '#ffffff',
            borderWidth: 2,
          }]
        });
      } catch (err) {
        setError('Failed to load profit data');
        console.error('Error fetching profit data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfitData();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#ffffff',
          font: {
            size: 14
          }
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: '#ffd700',
        bodyColor: '#ffffff',
        borderColor: '#ffd700',
        borderWidth: 1,
        padding: 10,
        callbacks: {
          label: (context) => `Profit: ${context.parsed.y.toFixed(2)} points`
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
          drawBorder: false,
        },
        ticks: {
          color: '#ffffff',
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
          drawBorder: false,
        },
        ticks: {
          color: '#ffffff',
          callback: (value) => `${value.toFixed(0)} pts`
        },
        beginAtZero: true
      }
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    animation: {
      duration: 1000,
      easing: 'easeInOutQuart'
    }
  };

  if (loading) {
    return (
      <div className={styles.chartContainer}>
        <h2>Profit Progression</h2>
        <div className={styles.loading}>Loading chart data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.chartContainer}>
        <h2>Profit Progression</h2>
        <div className={styles.error}>{error}</div>
      </div>
    );
  }

  if (!chartData) {
    return (
      <div className={styles.chartContainer}>
        <h2>Profit Progression</h2>
        <div className={styles.noData}>No data available</div>
      </div>
    );
  }

  return (
    <div className={styles.chartContainer}>
      <h2>Profit Progression</h2>
      <div className={styles.chartWrapper}>
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ProfitChart; 