import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Footer.module.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.section}>
          <h2 className={styles.title}>MisterTipster</h2>
          <p className={styles.description}>
            Your trusted source for Horse Racing tips and predictions.
          </p>
          <div className={styles.social}>
            <a 
              href="https://www.instagram.com/mistertipsteruk/" 
              target="_blank" 
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <i className="fab fa-instagram"></i> Follow us on Instagram
            </a>
          </div>
        </div>

        <div className={styles.section}>
          <h2 className={styles.title}>Quick Links</h2>
          <ul className={styles.links}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/stats">Statistics</Link></li>
            <li><Link to="/vip/tips">VIP Area</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>

        <div className={styles.section}>
          <h2 className={styles.title}>Legal</h2>
          <ul className={styles.links}>
            <li><Link to="/terms">Terms of Service</Link></li>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/disclaimer">Disclaimer</Link></li>
            <li><Link to="/responsible-gambling">Responsible Gambling</Link></li>
          </ul>
        </div>
      </div>

      <div className={styles.bottom}>
        <p>© 2024 MisterTipster. All rights reserved.</p>
        <p className={styles.gambleAware}>
          Please gamble responsibly. For help, visit{' '}
          <a href="https://www.begambleaware.org" target="_blank" rel="noopener noreferrer">
            BeGambleAware.org
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;