import { Analytics } from "@vercel/analytics/react";
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import TipsPage from './pages/TipsPage';
import ResultsPage from './pages/ResultsPage';
import AnalyticsPage from './pages/AnalyticsPage';
import VipPage from './pages/VipPage';
import ProtectedRoute from './components/ProtectedRoute';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import AccountSettings from './pages/AccountSettings';
import Layout from './components/Layout';
import './styles/globals.css';
import EmailVerification from './components/EmailVerification';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ContactPage from './pages/ContactPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import DisclaimerPage from './pages/DisclaimerPage';
import ResponsibleGamblingPage from './pages/ResponsibleGamblingPage';
import StatsPage from './pages/StatsPage';
import MembershipPage from './pages/MembershipPage';
import WelcomePage from './pages/WelcomePage';
import CompleteAccountPage from './pages/CompleteAccountPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import SubscriptionError from './pages/SubscriptionError';
import SubscriptionRedirect from './pages/SubscriptionRedirect';
import AccountSubscriptionSuccess from './pages/AccountSubscriptionSuccess';
import SetPassword from './pages/SetPassword';
import NotFoundPage from './pages/NotFoundPage';
import { hasValidToken } from './services/authService';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const isValid = hasValidToken();
      setIsAuthenticated(isValid);
      setLoading(false);
    };

    checkAuth();
    
    // Listen for storage events (logout from other tabs)
    window.addEventListener('storage', checkAuth);
    return () => window.removeEventListener('storage', checkAuth);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout><LandingPage /></Layout>
    },
    {
      path: "/login",
      element: <Layout><LoginPage /></Layout>
    },
    {
      path: "/stats",
      element: <Layout><StatsPage /></Layout>
    },
    {
      path: "/membership",
      element: <Layout><MembershipPage /></Layout>
    },
    {
      path: "/contact",
      element: <Layout><ContactPage /></Layout>
    },
    {
      path: "/results",
      element: <Layout><ResultsPage /></Layout>
    },
    {
      path: "/vip",
      element: (
        <Layout>
          <ProtectedRoute requiresSubscription={true}>
            <VipPage />
          </ProtectedRoute>
        </Layout>
      ),
      children: [
        {
          path: "tips",
          element: <TipsPage />
        },
        {
          path: "results",
          element: (
            <ProtectedRoute requiresTier={['gold']}>
              <ResultsPage />
            </ProtectedRoute>
          )
        },
        {
          path: "analytics",
          element: (
            <ProtectedRoute requiresTier={['gold']}>
              <AnalyticsPage />
            </ProtectedRoute>
          )
        },
        {
          index: true,
          element: <Navigate to="/vip/tips" replace />
        }
      ]
    },
    {
      path: "/account",
      element: (
        <Layout>
          <ProtectedRoute requiresSubscription={false}>
            <AccountSettings />
          </ProtectedRoute>
        </Layout>
      )
    },
    {
      path: "/subscription-error",
      element: <Layout><SubscriptionError /></Layout>
    },
    {
      path: "/account/subscription-success",
      element: (
        <Layout>
          <ProtectedRoute requiresSubscription={false}>
            <AccountSubscriptionSuccess />
          </ProtectedRoute>
        </Layout>
      )
    },
    {
      path: "/verify/:token",
      element: <Layout><EmailVerification /></Layout>
    },
    {
      path: "/welcome",
      element: <WelcomePage />
    },
    {
      path: "/complete-account/:token",
      element: <CompleteAccountPage />
    },
    {
      path: "/change-password",
      element: (
        <Layout>
          <ProtectedRoute requiresSubscription={false}>
            <ChangePasswordPage />
          </ProtectedRoute>
        </Layout>
      )
    },
    {
      path: "/subscription-redirect",
      element: <Layout><SubscriptionRedirect /></Layout>
    },
    {
      path: "/set-password/:uid/:token",
      element: <SetPassword />
    },
    {
      path: "/terms",
      element: <Layout><TermsPage /></Layout>
    },
    {
      path: "/privacy",
      element: <Layout><PrivacyPage /></Layout>
    },
    {
      path: "/disclaimer",
      element: <Layout><DisclaimerPage /></Layout>
    },
    {
      path: "/responsible-gambling",
      element: <Layout><ResponsibleGamblingPage /></Layout>
    },
    {
      path: "/forgot-password",
      element: <Layout><ForgotPasswordPage /></Layout>
    },
    {
      path: "/reset-password/:uid/:token",
      element: <Layout><ResetPasswordPage /></Layout>
    },
    {
      path: "*",
      element: <Layout><NotFoundPage /></Layout>
    }
  ], {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true
    }
  });

  return (
    <>
      <RouterProvider router={router} />
      <Analytics />
    </>
  );
}

export default App;