import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../styles/Navbar.module.css';
import logo from '../logo2.png'; 

const Navbar = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  const user = localStorage.getItem('user');
  const isLoggedIn = !!(token && user);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    navigate('/login');
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navContainer}>
      <Link to="/" className={styles.logo} onClick={closeMenu}>
          <img src={logo} alt="MisterTipster Logo" className={styles.logoImage} />
        </Link>
        
        <div className={`${styles.navLinks} ${isMenuOpen ? styles.active : ''}`}>
          <Link to="/stats" className={styles.navLink} onClick={closeMenu}>
            Statistics
          </Link>
          <Link to="/results" className={styles.navLink} onClick={closeMenu}>
            Results
          </Link>
          <Link to="/membership" className={styles.navLink} onClick={closeMenu}>
            Membership
          </Link>
          <Link to="/contact" className={styles.navLink} onClick={closeMenu}>
            Contact
          </Link>
          
          {isLoggedIn ? (
            <>
              <Link to="/VIP" className={styles.navLink} onClick={closeMenu}>
                VIP Area
              </Link>
              <Link to="/account" className={styles.navLink} onClick={closeMenu}>
                Account Settings
              </Link>
              <button onClick={handleLogout} className={styles.logoutButton}>
                Log Out
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className={styles.navLink} onClick={closeMenu}>
                Log In
              </Link>
            </>
          )}
        </div>

        <button 
          className={styles.hamburger} 
          onClick={toggleMenu} 
          aria-label="Menu"
        >
          <span className={`${styles.hamburgerIcon} ${isMenuOpen ? styles.open : ''}`}></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;