import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { getCurrentUser, hasValidToken, getAuthHeader } from '../services/authService';
import { checkSubscription } from '../services/subscriptionService';
import axios from 'axios';

const ProtectedRoute = ({ children, requiresSubscription = false, requiresTier = [] }) => {
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAccess = async () => {
      try {
        // Check if user is logged in
        if (!hasValidToken()) {
          navigate('/login');
          return;
        }

        // If subscription check is required
        if (requiresSubscription || requiresTier.length > 0) {
          const response = await axios.get('/api/subscription/check/', {
            headers: getAuthHeader()
          });

          const userTier = response.data.subscription_tier;
          
          // Store tier in localStorage for other components
          localStorage.setItem('userTier', userTier);

          // Check if user has required tier
          if (requiresTier.length > 0 && !requiresTier.includes(userTier)) {
            navigate('/account');
            return;
          }

          // Check if user has active subscription
          if (requiresSubscription && !response.data.is_subscribed) {
            navigate('/membership');
            return;
          }
        }

        setHasAccess(true);
      } catch (error) {
        console.error('Access check error:', error);
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    checkAccess();
  }, [navigate, requiresSubscription, requiresTier]);

  if (loading) return <LoadingSpinner />;
  return hasAccess ? children : null;
};

export default ProtectedRoute;