import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/WelcomePage.module.css';

const WelcomePage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.welcomeContainer}>
      <div className={styles.welcomeCard}>
        <h1>Welcome to Mister Tipster!</h1>
        <div className={styles.checkmark}>✓</div>
        <p className={styles.successMessage}>
          Your subscription has been activated successfully
        </p>
        <p className={styles.instructions}>
          Please check your email to complete your account setup.
          We've sent you a secure link to set your password.
        </p>
        <button 
          onClick={() => navigate('/')}
          className={styles.homeButton}
        >
          Go to Homepage
        </button>
      </div>
    </div>
  );
};

export default WelcomePage; 