import axios from 'axios';
import { getAuthHeader } from '../services/authService';

// Create axios instance
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Add auth headers to every request
        const headers = getAuthHeader();
        config.headers = {
            ...config.headers,
            ...headers
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            // Handle token expiration
            localStorage.removeItem('token');  // or however you store your token
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;