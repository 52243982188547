import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/SubscriptionSuccess.module.css';
import { hasValidToken } from '../services/authService';

const AccountSubscriptionSuccess = () => {
  const isLoggedIn = hasValidToken();

  return (
    <div className={styles.container}>
      <div className={styles.successCard}>
        <h1>Subscription Activated!</h1>
        <p>Thank you for subscribing to MisterTipster VIP!</p>
        <p>Your account has been upgraded with VIP access.</p>
        
        <div className={styles.actionButtons}>
          {isLoggedIn ? (
            <Link to="/vip/tips" className={styles.primaryButton}>
              View VIP Tips
            </Link>
          ) : (
            <>
              <Link to="/login" className={styles.primaryButton}>
                Login Now
              </Link>
              <Link to="/forgot-password" className={styles.secondaryButton}>
                Forgot Password?
              </Link>
            </>
          )}
        </div>

        <div className={styles.features}>
          <h2>What's included in your subscription:</h2>
          <ul>
            <li>✓ Access to VIP tips</li>
            <li>✓ Premium features</li>
            <li>✓ Exclusive content</li>
          </ul>
        </div>

        <div className={styles.support}>
          <p>
            Need help? Contact{' '}
            <a href="mailto:support@mistertipster.co.uk">
              support@mistertipster.co.uk
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountSubscriptionSuccess; 