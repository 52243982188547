import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/LandingPage.module.css';
import ProfitChart from '../components/ProfitChart';
import { FaTrophy, FaChartLine, FaBell } from 'react-icons/fa';
import { calculateProfitLoss } from '../utils/calculateProfitLoss';

const LandingPage = () => {
  const [stats, setStats] = useState(null);
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const baseUrl = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL;
      const response = await axios.get(`${baseUrl}/api/tips/public/tips/`);
      const completedTips = response.data.filter(tip => tip.result !== 'PENDING');
      setTips(completedTips);

      const now = new Date();
      const monthlyTips = filterTipsByDate(completedTips, now, 30);
      const monthlyResult = calculateProfitLoss(monthlyTips);
      const allTimeResult = calculateProfitLoss(completedTips);

      setStats({
        monthlyProfit: monthlyResult.profitInPoints.toFixed(2),
        allTimeProfit: allTimeResult.profitInPoints.toFixed(2)
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterTipsByDate = (tips, currentDate, days) => {
    const cutoffDate = new Date(currentDate);
    cutoffDate.setDate(cutoffDate.getDate() - days);
    return tips.filter(tip => new Date(tip.date) >= cutoffDate);
  };

  return (
    <div className={styles.landingPage}>
      {/* Hero Section */}
      <section className={styles.hero}>
        <div className={styles.heroContent}>
          <h1>Data-Driven Horse Racing Tips</h1>
          <p>Join thousands of members profiting from our proven algorithms</p>
          <div className={styles.heroButtons}>
            <Link to="/membership" className={styles.primaryButton}>Join Now</Link>
            <Link to="/stats" className={styles.secondaryButton}>View Our Results</Link>
          </div>
        </div>
      </section>

      {/* Stats Overview */}
      {!loading && stats && (
        <section className={styles.statsOverview}>
          <div className={styles.statCard}>
            <h3>All-Time Profit</h3>
            <p className={`${styles.statValue} ${Number(stats.allTimeProfit) >= 0 ? styles.positive : styles.negative}`}>
              {stats.allTimeProfit} points
            </p>
          </div>
        </section>
      )}

      {/* Performance Chart */}
      <section className={styles.chartSection}>
        <h2>Proven Track Record</h2>
        <ProfitChart tips={tips} />
        <Link to="/stats" className={styles.secondaryButton}>View Detailed Statistics</Link>
      </section>

      {/* Features */}
      <section className={styles.features}>
        <div className={styles.feature}>
          <FaChartLine className={styles.icon} />
          <h3>Data-Driven</h3>
          <p>Advanced algorithms analyze thousands of races</p>
        </div>
        <div className={styles.feature}>
          <FaTrophy className={styles.icon} />
          <h3>Proven Results</h3>
          <p>Consistent profits backed by transparent stats</p>
        </div>
        <div className={styles.feature}>
          <FaBell className={styles.icon} />
          <h3>Instant Tips</h3>
          <p>Get notifications as soon as tips are ready</p>
        </div>
      </section>

      {/* Final CTA */}
      <section className={styles.finalCTA}>
        <h2>Start Winning Today</h2>
        <Link to="/membership" className={styles.primaryButton}>View Membership Options</Link>
      </section>
    </div>
  );
};

export default LandingPage;