import React, { useState, useEffect } from 'react';
import styles from '../styles/StatsPage.module.css';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import { calculateProfitLoss } from '../utils/calculateProfitLoss';
import ProfitChart from '../components/ProfitChart';

const StatsPage = () => {
  const [stats, setStats] = useState({
    daily: { tips: [], profit: 0 },
    weekly: { tips: [], profit: 0 },
    monthly: { tips: [], profit: 0 },
    annual: { tips: [], profit: 0 },
    allTime: { tips: [], profit: 0 },
    monthlyHistory: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchTips();
  }, []);

  const fetchTips = async () => {
    try {
      const baseUrl = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL;
      const response = await axios.get(`${baseUrl}/api/tips/public/tips/`);
      const completedTips = response.data.filter(tip => tip.result !== 'PENDING');
      
      // For debugging
      console.log('Completed tips:', completedTips);
      
      const now = new Date();
      const stats = {
        daily: {
          tips: filterTipsByDate(completedTips, now, 1),
          profit: 0
        },
        weekly: {
          tips: filterTipsByDate(completedTips, now, 7),
          profit: 0
        },
        monthly: {
          tips: filterTipsByDate(completedTips, now, 30),
          profit: 0
        },
        annual: {
          tips: filterTipsByDate(completedTips, now, 365),
          profit: 0
        },
        allTime: {
          tips: completedTips,
          profit: 0
        },
        monthlyHistory: calculateMonthlyHistory(completedTips)
      };

      // Calculate profits for each period
      Object.keys(stats).forEach(period => {
        if (period !== 'monthlyHistory' && stats[period].tips.length > 0) {
          const result = calculateProfitLoss(stats[period].tips);
          console.log(`${period} calculation:`, {
            tips: stats[period].tips,
            result
          });
          stats[period].profit = result.profitInPoints;
        }
      });

      setStats(stats);
    } catch (err) {
      console.error('Error fetching tips:', err);
      setError('Failed to load statistics. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const filterTipsByDate = (tips, currentDate, days) => {
    const cutoffDate = new Date(currentDate);
    cutoffDate.setDate(cutoffDate.getDate() - days);
    return tips.filter(tip => new Date(tip.date) >= cutoffDate);
  };

  const calculateMonthlyHistory = (tips) => {
    const monthlyTips = {};
    
    tips.forEach(tip => {
      const date = new Date(tip.date);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      
      if (!monthlyTips[monthKey]) {
        monthlyTips[monthKey] = [];
      }
      monthlyTips[monthKey].push(tip);
    });

    return Object.entries(monthlyTips)
      .map(([date, monthTips]) => {
        const result = calculateProfitLoss(monthTips);
        return {
          date: new Date(date).toLocaleDateString('en-GB', { year: 'numeric', month: 'long' }),
          tips: monthTips,
          profit: result?.profitInPoints || 0
        };
      })
      .sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.statsContainer}>
      <h1>Performance Statistics</h1>
      <div className={styles.pointsExplanation}>
        <h2>Understanding Our Points System</h2>
        <p>All profits are measured in points, where 1 point = 1% of your betting bank. For example:</p>
        <ul>
          <li>5 points on a £100 bank = £5 stake</li>
          <li>5 points on a £1,000 bank = £50 stake</li>
        </ul>
        
        <h3>Our Standard Staking Plan:</h3>
        <ul>
          <li><strong>NAP</strong> - 3 points</li>
          <li><strong>Solid Pick</strong> - 2 points</li>
          <li><strong>Value Pick</strong> - 1 point</li>
          <li><strong>Each Way Pick</strong> - 0.5 points total (split as 0.25 win, 0.25 place)</li>
        </ul>
        
        <p><em>Note: Each Way bets are split equally between win and place parts. For example, a 0.5 point Each Way bet means 0.25 points on the win and 0.25 points on the place.</em></p>
      </div>

      {/* Add the chart here, after the explanation and before the stats grid */}
      <ProfitChart tips={stats.allTime.tips} />

      <div className={styles.statsGrid}>
        <div className={styles.statCard}>
          <h3>Today's Profit</h3>
          <p className={`${styles.profitValue} ${(stats.daily.profit || 0) >= 0 ? styles.positive : styles.negative}`}>
            {(stats.daily.profit || 0).toFixed(2)} points
          </p>
        </div>

        <div className={styles.statCard}>
          <h3>Last 7 Days</h3>
          <p className={`${styles.profitValue} ${(stats.weekly.profit || 0) >= 0 ? styles.positive : styles.negative}`}>
            {(stats.weekly.profit || 0).toFixed(2)} points
          </p>
        </div>

        <div className={styles.statCard}>
          <h3>Last 30 Days</h3>
          <p className={`${styles.profitValue} ${(stats.monthly.profit || 0) >= 0 ? styles.positive : styles.negative}`}>
            {(stats.monthly.profit || 0).toFixed(2)} points
          </p>
        </div>

        <div className={styles.statCard}>
          <h3>Last 365 Days</h3>
          <p className={`${styles.profitValue} ${(stats.annual.profit || 0) >= 0 ? styles.positive : styles.negative}`}>
            {(stats.annual.profit || 0).toFixed(2)} points
          </p>
        </div>

        <div className={styles.statCard}>
          <h3>All Time Profit</h3>
          <p className={`${styles.profitValue} ${(stats.allTime.profit || 0) >= 0 ? styles.positive : styles.negative}`}>
            {(stats.allTime.profit || 0).toFixed(2)} points
          </p>
        </div>
      </div>

      <div className={styles.monthlyHistory}>
        <h2>Monthly Performance History</h2>
        <div className={styles.monthlyGrid}>
          {stats.monthlyHistory.map((month) => (
            <div key={month.date} className={styles.monthCard}>
              <h3>{month.date}</h3>
              <p className={`${styles.profitValue} ${(month.profit || 0) >= 0 ? styles.positive : styles.negative}`}>
                {(month.profit || 0).toFixed(2)} points
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatsPage;